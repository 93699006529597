var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex items-center"},[(!_vm.legacyDonate)?_c('BroadcasterDonateButton',{staticClass:"rounded-full py-2.5 sm:py-3 px-12 sm:px-20 bg-black text-white text-2xl font-light m-auto whitespace-nowrap",attrs:{"broadcaster":_vm.broadcaster,"override-donation-options":[
      {
        method: _vm.BroadcasterDonationMethod.Cornerstone,
        url: 'https://securedonors.com/thevault',
      },
      {
        method: _vm.BroadcasterDonationMethod.Paypal,
        email: 'info@sermonaudio.com',
        designation: 'The Vault',
      },
    ]}},[_c('span',[_vm._v(_vm._s(_vm.$t('Give Online')))])]):_vm._e(),_vm._v(" "),(_vm.legacyDonate)?_c('button',{staticClass:"rounded-full py-2.5 sm:py-3 px-12 sm:px-20 bg-black text-white text-2xl font-light m-auto whitespace-nowrap",on:{"click":function($event){return _vm.$modal.show('donate')}}},[_c('span',[_vm._v(_vm._s(_vm.$t('Give Online')))])]):_vm._e(),_vm._v(" "),(_vm.legacyDonate)?_c('modal',{attrs:{"name":"donate","focus-trap":"","adaptive":"","height":"auto","width":"560px"},on:{"before-open":function($event){return _vm.setModal(true)},"before-close":function($event){return _vm.setModal(false)}}},[_c('div',{staticClass:"donate-modal"},[_c('div',{staticClass:"donate-iframe-header"},[_c('div',{staticClass:"ellipsis"},[_c('span',[_vm._v(_vm._s(_vm.$t('Give to the SermonAudio Vault')))])]),_vm._v(" "),_c('button',{staticClass:"modal-close-button",on:{"click":function($event){return _vm.$modal.hide('donate')}}},[_c('SaIcon',{attrs:{"name":"close"}})],1)]),_vm._v(" "),_c('div',{staticClass:"donation-iframe-container"},[_c('iframe',{staticClass:"absolute-fill w-full",attrs:{"loading":"lazy","tabindex":"-1","width":"1","height":"540","frameborder":"0","src":`https://www.sermonaudio.com/secure/paydonate.asp?sourceid=misc&vuestylesheet=${_vm.baseUrl}/vault/legacy-payment-override.css`}})])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }